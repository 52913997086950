const ROUTE = {
    LANDINGPAGE: "/",
    DASHBOARD: "/dashboard",
    MEMBER_GROUP: "/memberGroup",
    ADD_MEMBER_GROUP: "/addMemberGroup",
    ADD_MEMBER: "/addMember",
    SCHEME: "/scheme",
    VIEW_MEMBER_DETAIL: "/viewMemberDetail",
    ADD_SCHEME: "/addScheme",
    LOANAPPLICATION: "/loanApplication",
    LOANAPPROVAL: "/loanApproval",
    DISBURSE_LOAN: "/disburseLoan",
    LOAN_APPROVAL_DETAILS: "/loanApprovalDetails",
    ALL_APPLICATIONS: "/allApplications",
    EMICALCULATOR: "/emiCalculator",
    DUE_EMIS: "/dueEmis",
    PAID_DETAIL_VIEW: "/paidDetailView",
    RECIEVE_DETAIL_VIEW: "/recieveDetailView",
    PROCESSING_FEE: "/processingFee",
    PROFIT: "/profit",
    ADD_PROCESSING_FEE: "/addProcessingFee",
    CLOSE_ACCOUNT: "/closeAccount",
    OUTSTANDING_DETAIL_VIEW: "/outstandingDetailView",
    EXPENSE: "/expense",
    ADD_EXPENSE: "/addExpense",
    LIST_USER: "/listUser",
    ADD_USER: "/addUser",
    VILLAGE: "/village",
    ADD_VILLAGE: "/addVillage",
    MEMBER_VILLAGE_MAP: "/memberVillageMap",
    EMI_APPROVAL: "/emiApproval",
    LOGIN: "/login",
    REGISTRATION: "/registration",
    MEMBER: "/member",
    USER_MENUS: "/userMenus",
    EDIT_EXPENSES: "/editExpense",
    EDIT_PROCESSING_FEE: "/editProcessingFee",
    EDIT_LOAN: "/editLoanApplication",
    EDIT_SCHEME: "/editScheme",
    EDIT_MEMBER: "/editMember",
    EDIT_MEMBER_GROUP: "/editMemberGroup",
    EDIT_VILLAGE: "/editVillage",
    PRINT_LOAN: "/print_loan",
    DOWNLOAD_DOC: "/downloadDoc",
    OPEN_SAVING_ACCOUNT: "/openSavingAccount",
    SAVING_ACCOUNT_REPORT: "/savingAccoutReport",
    SVAC_DEPOSITED_REPORT: "/svac_deposited_report",
    PASSBOOK: "/passbook",
    ADD_TRANSACTION_SVAC: "/add_transaction_svac",
    LOAN_CASH_AC_TRANSFER: "/loanCashAcTransfer",
    EMI_COLLECTION_SHEET: "/collectionSheet",
    INVESTOR_DASHBOARD: "/adminInvestorReport",
    CLOSE_GROUP_LOAN_ACCOUNT: "/closeLoanAccount",
    DASHBOARD_INVESTOR: "/investorDashboard",
    MEMBER_APPROVAL: "/memberApproval",
    ADMIN_MAIN_LEDGER_DETAIL: "/adminMainLedgerDetail",
    PROFIT_LEDGER_DETAILS: "/profitLedgerDetails",
    MAIN_LEDGER_DETAIL_INVESTOR: "/mainLedgerDetailInvestor",
    COMPANY_EXPENSE_SLIP_PDF: "/companyExpenseSlipPdf",
    LOAN_ACTIONS: "/loan_actions",
    OVERDUES: "/overdueEmis",
    OVERDUE_NOTICE: "/overdueNotice",
    CASHBOOK: "/cashbook",
    LOAN_DELETION_APPROVAL: "/loan_deletion_approval",
    PRINT_JOINT_LIABILITY: "/print_joint_liability",
    LOAN_LAST_EMI_DEPOSIT_DIFF: "/lastEmiDepositReport",
    LOAN_TOPUP: "/loanTopup",
    DATABASE_BACKUP: "/database_backups",
    CLOSED_LOANS_LIST: "/allClosedLoans",
    PARTIAL_EMI_DEPOSIT: "/partialEmiDeposit",
    PARTIAL_EMI_APPROVAL: "/partialEmiApproval",
    LIST_LOAN_PANELTY: "/list_loan_panelty"

}
export default ROUTE;